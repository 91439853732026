import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.less";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Alpine from "alpinejs";
import AOS from "aos";
import "aos/dist/aos.css";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).Alpine = Alpine;

Alpine.start();
AOS.init({
  once: true,
});
createApp(App).use(router).mount("#app");
