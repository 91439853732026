/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */
export const isSHA256HexFormat = (v: string): boolean => {
  const pattern = /^[a-f0-9]{64}$/gi;
  return pattern.test(v);
};

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | null;
  let isImmediate = true;

  return function (this: any, ...args: Parameters<T>): void {
    const context = this;

    if (isImmediate) {
      func.apply(context, args);
      isImmediate = false;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      isImmediate = true;
    }, delay);
  };
}
