<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<template>
  <section class="bg-[#fff] text-black" v-if="postDetail">
    <div class="mx-auto w-full max-w-7xl px-5 py-12 md:px-10 md:py-16 lg:py-20">
      <div class="mb-10">
        <p class="mb-10 text-[#acacaf]">{{ postDetail?.time }}</p>
        <h1 class="mb-10 max-w-7xl text-4xl font-medium md:text-6xl">
          {{ translateLanguage(postDetail?.name || "") }}
        </h1>
      </div>
      <div
        class="p-4"
        v-html="translateLanguage(postDetail?.detail || '')"
      ></div>
    </div>
  </section>
  <section id="post">
    <!-- Container -->
    <div class="mx-auto w-full max-w-7xl px-5 py-0 mb-10">
      <!-- Component -->
      <div class="flex flex-col items-center">
        <div
          @click="toHome"
          class="inline-block rounded-xl bg-[#2f6ea2] px-8 py-4 text-center font-semibold text-white hover:cursor-pointer"
        >
          {{ translateLanguage("返回首页") }}
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { postItemFind } from "@/api";
import { useRouter, useRoute } from "vue-router";
import { parseISO, format } from "date-fns";
import { translateLanguage } from "@/utils/translate";
const router = useRouter();
const route = useRoute();
const postId = ref<number>(0);

onMounted(() => {
  const { id } = route.query;
  postId.value = Number(id);
  postPost();
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postDetail = ref<any>();
const postPost = async () => {
  const res = await postItemFind(postId.value);
  res.time = format(parseISO(res.time), "yyyy-MM-dd HH:mm:ss");
  postDetail.value = res;
};

const toHome = () => {
  router.push({ path: "/" });
};
</script>
