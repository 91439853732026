// eslint-disable @typescript-eslint/no-explicit-any
<template>
  <section class="relative">
    <div
      class="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 lg:pb-32 lg:pt-10"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <h1
        class="z-10 mb-10 text-center text-4xl font-bold uppercase md:mb-16 md:text-5xl"
      >
        {{ translateLanguage("深港跨境数据验证平台") }}
      </h1>
      <div>
        <div class="mx-auto grid w-full gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div
            v-for="item in postList"
            :key="item.id"
            @click="viewActicle(item.id)"
            class="hover:cursor-pointer flex flex-col items-start gap-4 pb-6 text-black sm:items-start"
          >
            <div
              class="relative aspect-[16_/_7] w-full overflow-hidden rounded-sm md:aspect-[16_/_8]"
            >
              <img
                :src="
                  '/upload' +
                  (item.thumbnail && item.thumbnail.length >= 0
                    ? item.thumbnail[0]
                    : '')
                "
                alt=""
                class="absolute inline-block h-full w-full object-cover"
              />
            </div>
            <div
              class="flex w-full flex-col items-start gap-5 pt-4 md:gap-0 md:pt-0"
            >
              <p class="mb-3 text-xl font-bold md:text-2xl">
                {{ translateLanguage(item.name) }}
              </p>
              <div
                class="flex w-full flex-col justify-between gap-3 text-[#636262] sm:w-auto md:flex-row lg:items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 6 6"
                  fill="none"
                  preserveAspectRatio="xMidYMid meet"
                  class="hidden h-1.5 w-1.5 items-center justify-center text-[#e2e2e2] lg:block"
                >
                  <circle cx="3" cy="3" r="3" fill="currentColor"></circle>
                </svg>
                <p class="text-sm">{{ item.time }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="post">
    <!-- Container -->
    <div class="mx-auto w-full max-w-7xl px-5 py-0 mb-10">
      <!-- Component -->
      <div class="flex flex-col items-center">
        <div
          @click="toHome"
          class="inline-block rounded-xl bg-[#2f6ea2] px-8 py-4 text-center font-semibold text-white hover:cursor-pointer"
        >
          {{ translateLanguage("返回首页") }}
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { postListGet } from "@/api";
import { parseISO, format, compareDesc } from "date-fns";
import { translateLanguage } from "@/utils/translate";
import { useRouter } from "vue-router";
const router = useRouter();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postList = ref<any[]>([]);
const getPostList = async () => {
  const res = await postListGet();
  postList.value = res.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((item: any) => item.mode === "enabled")
    .map((item) => {
      return {
        ...item,
        time: format(parseISO(item.time), "yyyy-MM-dd HH:mm:ss"),
      };
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .sort((a: any, b: any) => compareDesc(parseISO(a.time), parseISO(b.time)));
};
getPostList();

const viewActicle = (id: number | string) => {
  router.push({ name: "acticle", query: { id } });
};
const toHome = () => {
  router.push({ path: "/" });
};
</script>
