/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  type InternalAxiosRequestConfig,
  type AxiosInstance,
  type AxiosResponse,
} from "axios";

const requestUrl = "/api/";
// https://cbdv.cnix.cn/api/admin/post/search
// console.log(123414, import.meta as any);

// const requestUrl =
//   (import.meta as any).env.VITE_HOST + (import.meta as any).env.VITE_BASE_URL;

const http: AxiosInstance = axios.create({
  baseURL: requestUrl,
  timeout: 6000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

http.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const get = <T>(
  url: string,
  config?: InternalAxiosRequestConfig
): Promise<T> => {
  return http.get<T>(url, config).then((response) => response.data);
};

export const post = <T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any,
  config?: InternalAxiosRequestConfig
): Promise<T> => {
  return http.post<T>(url, data, config).then((response) => response.data);
};
export default http;
