import { post, get } from "@/utils/request";

export function uploadHash(weId: string, sha256sum: string, password: string) {
  return post<uploadHashModel>("/upload-hash", { weId, sha256sum, password });
}
export function verifyHash(sha256sum: string, weId: string, password: string) {
  const params: verifyParams = {
    sha256sum,
    weId,
    password,
  };
  if (weId) {
    params.weId = weId;
  }
  return post<verifyHashModel>("/verify-hash", params);
}

export function checkWeid(weId: string, password: string) {
  return post<checkWeIdModel>("/check-weid", { weId, password });
}

export function postListGet() {
  return get<postList>("/admin/post/search");
}

export function postItemFind(postId: number) {
  return get<postItem>(`/admin/post/find?id=${postId}`);
}

export function bannerListGet() {
  return get<postList>("/admin/banner/search");
}
